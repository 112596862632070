<template>
    <div>
        <div class="text-center">
            <h2 class="mb-2">Pour commencer,</h2>
            <h2>Choisissez l'une de ces options</h2>
        </div>

        <!-- Form to select orientation reason -->
        <v-form class="pl-8 mt-12 mb-12" ref="orientationForm">
            <v-radio-group :rules="[rules.required]" v-model="orientation.reason"
                           @change="handleReasonChange(orientation)">
                <v-radio :color="radioColor" label="Je suis envoyé.e par la Ligue contre le cancer"
                         value="ligue"></v-radio>

                <v-radio :color="radioColor" label="AJA" value="aja" @click="openAjaInformations"></v-radio>

                <!-- AJA role selection, always visible but disabled unless "AJA" is selected -->
                <v-radio-group :rules="isAja ? [rules.required] : []" class="mt-0 ml-12"
                               v-model="orientation.aja_role">
                    <v-radio :color="radioColor" :disabled="!isAja" label="Je suis un jeune MAJEUR (18 - 26 ans)"
                             value="aja_young"></v-radio>
                    <v-radio :color="radioColor" :disabled="!isAja"
                             label="Je suis parent ou représentant légal d’un jeune MINEUR"
                             value="aja_assistant"></v-radio>
                </v-radio-group>

                <v-radio :color="radioColor" label="Ma couverture santé prévoyance est"
                         value="healthinsurance"></v-radio>

                <!-- Health insurance partners, always visible but disabled unless "healthinsurance" is selected -->
                <v-radio-group :rules="isHealthinsurance ? [rules.required] : []" class="mt-0 ml-12"
                               v-model="orientation.partner.id">
                    <v-radio v-for="partner in partners.healthinsurance" :key="partner.id" :color="radioColor"
                             :disabled="!isHealthinsurance" :label="partner.name" :value="partner.id"></v-radio>
                </v-radio-group>

                <!-- <v-radio :color="radioColor" label="Je suis retraité.e et ma complémentaire retraite est"
                         value="retreat"></v-radio> -->

                <!-- Retreat partners, always visible but disabled unless "retreat" is selected -->
                <!-- <v-radio-group :rules="isRetreat ? [rules.required] : []" class="mt-0 ml-12"
                               v-model="orientation.partner.id">
                    <v-radio v-for="partner in partners.retreat" :key="partner.id" :color="radioColor"
                             :disabled="!isRetreat" :label="partner.name" :value="partner.id"></v-radio>
                </v-radio-group> -->

                <v-radio :color="radioColor" label="Mon employeur est" value="employer"></v-radio>

                <!-- Employer partners, always visible but disabled unless "employer" is selected -->
                <v-radio-group :rules="isEmployer ? [rules.required] : []" class="mt-0 ml-12"
                               v-model="orientation.partner.id">
                    <v-radio v-for="partner in partners.employer" :key="partner.id" :color="radioColor"
                             :disabled="!isEmployer" :label="partner.name" :value="partner.id"></v-radio>
                </v-radio-group>

                <v-radio :color="radioColor" label="Je suis envoyé.e par Patients en réseau"
                         value="patientsreseau"></v-radio>

                <v-radio :color="radioColor" class="big-label" label="Autres (majorité des cas de création de compte)"
                         value="other"></v-radio>
            </v-radio-group>

            <v-btn :color="buttonColor" @click="submitOrientationForm">Continuer</v-btn>
        </v-form>

        <!-- Dialog for AJA Information -->
        <v-dialog persistent v-model="ajaInformationsDialog" max-width="1000">
            <v-card style="background-color: #1E3945;" class="text-center pt-6">
                <v-card-title>
                    <h2>Accueil sur la Plateforme onCOGIT’aja</h2>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="ajaInformationsDialog = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="text-left">
                    Bonjour, Vous allez vous inscrire au parcours de remédiation cognitive onCOGIT’aja réservé aux
                    jeunes de 15 à
                    26 ans ayant été soignés pour un cancer (et leurs aidants).<br /><br />
                    Ce parcours suit le cheminement ci-dessous:
                    <ul>
                        <li>Accord du jeune majeur (ou de ses parents pour les mineurs) pour participer à une prise en
                            charge
                            neuropsychologique et inscription en ligne sur la plateforme onCOGIT’aja.
                        </li>
                        <li>Dans le cadre du projet, les AJA et leurs accompagnants se voient offrir leur adhésion à
                            onCOGITE (de
                            20€/an)
                        </li>
                        <li>Remplissage des questionnaires de renseignement et d’auto-évaluation par le jeune</li>
                        <li>Remplissage des questionnaires de renseignement et d’hétéro-évaluation par les parents ou un
                            aidant.
                        </li>
                        <li>Possibilité de participation à un (ou 2) webinaire d’informations pour les Parents-Aidants.
                        </li>
                        <li>Participation à l’atelier d’éducation thérapeutique et d’information en ligne «infoCOG’aja»
                            avant de
                            débuter les ateliers de remédiation cognitive (1h30)
                        </li>
                        <li>Participation à 15 (ou+) ateliers en ligne de rééducation cognitive d’une heure, encadrés
                            par une
                            neuropsychologue spécialisée dans la prise en charge de l’oncobrain des jeunes.
                        </li>
                        <li>Remplissage des questionnaires de satisfaction et d’auto-évaluation par le jeune</li>
                        <li>Remplissage des questionnaires de satisfaction et d’hétéro-évaluation par les parents ou
                            l’aidant.
                        </li>
                    </ul>
                    <br />
                    Cet accompagnement est basé sur la souplesse afin de faciliter l’assiduité du jeune au cours de ses
                    15 séances
                    (ou +) de remédiation (une chaque semaine).<br /> <br />
                    Il n’y a pas d’engagement financier demandé aux participants ni à leur famille, en revanche, c’est
                    l’investissement personnel en temps qui fera le succès de la rééducation.<br /><br />
                    Les questionnaires sont anonymisés et les données collectives extraites de cette expérimentation
                    seront
                    exclusivement réservées à l’évaluation et l’amélioration de l’accompagnement qui sera proposé à
                    l’issue de
                    cette phase. Les participants auront connaissance de ces évaluations.<br /><br />
                    Merci de choisir entre ces 2 situations possibles:
                    <v-radio-group :rules="[rules.required]" class="mt-0 ml-12"
                                   v-model="orientation.aja_role">
                        <v-radio :color="radioColor" @click="closeAjaInformationsDialog"
                                 label="Je suis AJA MAJEUR et j’accepte de participer à onCOGIT’aja"
                                 value="aja_young"></v-radio>
                        <v-radio :color="radioColor" @click="closeAjaInformationsDialog"
                                 label="Je suis représentant légal d’un AJA Mineur et j’accepte que mon enfant participe à onCOGIT’aja"
                                 value="aja_assistant"></v-radio>
                    </v-radio-group>
                    <span>Si vous êtes parent/aidant d’un AJA Majeur vous ne pourrez vous inscrire qu'une fois que votre
                        jeune aura créé son compte</span>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data () {
        return {
            ajaInformationsDialog: false,

            orientation: this.$store.getters.getRegistrationProcess,

            partners: {
                healthinsurance: [],
                patientsreseau: [],
                employer: [],
                retreat: []
            },

            rules: {
                required: (value) => !!value || 'Champ requis'
            }
        }
    },
    computed: {
        ...mapGetters(['isThemeAja']),
        isHealthinsurance () {
            return this.orientation.reason === 'healthinsurance'
        },
        isEmployer () {
            return this.orientation.reason === 'employer'
        },
        isRetreat () {
            return this.orientation.reason === 'retreat'
        },
        isAja () {
            return this.orientation.reason === 'aja'
        },
        radioColor () {
            return this.isThemeAja ? '#4E983C' : 'primary'
        },
        buttonColor () {
            return this.isThemeAja ? '#4E983C' : 'primary'
        }
    },
    mounted () {
        this.fetchPartners()
    },
    methods: {
        ...mapActions(['setThemeAja']),
        async fetchPartners () {
            try {
                const healthInsurancePromise = this.$store.dispatch('partnersRequest', {
                    search: JSON.stringify({ support_type: 'healthinsurance' })
                })
                const retreatPromise = this.$store.dispatch('partnersRequest', {
                    search: JSON.stringify({ support_type: 'retreat' })
                })
                const employerPromise = this.$store.dispatch('partnersRequest', {
                    search: JSON.stringify({ support_type: 'employer' })
                })
                const patientsreseauPromise = this.$store.dispatch('partnersRequest', {
                    search: JSON.stringify({ support_type: 'patientsreseau' })
                })

                const [healthInsuranceResponse, retreatResponse, employerResponse, patientsreseauResponse] =
                    await Promise.all([
                        healthInsurancePromise,
                        retreatPromise,
                        employerPromise,
                        patientsreseauPromise
                    ])

                this.partners.healthinsurance = healthInsuranceResponse.data
                this.partners.retreat = retreatResponse.data
                this.partners.employer = employerResponse.data
                this.partners.patientsreseau = patientsreseauResponse.data
            } catch (error) {
                await this.$store.dispatch('setErrors', 'Une erreur est survenue lors de la récupération des partenaires')
            }
        },
        openAjaInformations () {
            this.ajaInformationsDialog = true
        },
        closeAjaInformationsDialog () {
            this.ajaInformationsDialog = false
        },
        handleReasonChange () {
            if (!this.orientation.reason) {
                return
            }

            // auto select partner id if there is only one
            if (this.partners[this.orientation.reason]?.length === 1) {
                this.orientation.partner.id = this.partners[this.orientation.reason][0].id
            } else {
                this.orientation.partner.id = null
            }

            if (this.orientation.reason !== 'aja') {
                this.orientation.aja_role = null
            }

            // update theme to aja if required
            this.setThemeAja(this.orientation.reason === 'aja')
        },
        submitOrientationForm () {
            const isValid = this.$refs.orientationForm.validate()
            if (isValid) {
                this.$emit('submitOrientation', this.orientation)
            }
        }
    }
}
</script>

<style>
.big-label .v-label {
    font-size: 24px;
    /* Adjust the font size as needed */
}

#aja-radio-group .v-label {
    font-size: 14px;
}
</style>
